import { gql } from '@apollo/client'
import { getUserFragment, getUserFragmentName, getUserInvitedFragment, getUserInvitedFragmentName, UserBasic, UserEmail, UserInvited } from 'models/user'
import { getUserCourseFragment, getUserCourseFragmentName, UserCourseResponseType } from 'models/user_course'

export type GetUserVars = { userId: string }
export type GetUserRes = { user: UserBasic }
export const GET_USER = gql`
  query GetUser($userId: String!) {
    user: getUser(userId: $userId) {
      email
      profile {
        fullName
      }
    }
  }
`

export const COPY_COMPANY_USER_PROGRESS = gql`
  mutation CopyCompanyUserProgress($fromCompanyId: String!, $toCompanyId: String!, $userId: String!) {
    res: copyCompanyUserProgress(fromCompanyId: $fromCompanyId, toCompanyId: $toCompanyId, userId: $userId)
  }
`
export type CopyCompanyUserProgressArgs = {
  fromCompanyId: string,
  toCompanyId: string,
  userId: string,
}
export type CopyCompanyUserProgressRes = {
  res: string,
}
export const EXPORT_COMPANY_INVITED_USERS = gql`
  query ExportCompanyInvitedUsers(
    $organizationId: String!, $companyId: String!,
    $searchText: String!
  ) {
    exportCompanyInvitedUsers(
      organizationId: $organizationId, companyId: $companyId,
      searchText: $searchText
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`

export type GetUserMetadataRes = {
  user: {
    id: string;
    metadata: {
      [key: string]: any;
    }
  }
}

export const GET_USER_METADATA = gql`
  query GetUserMetadata($userId: String!){
    user: getUser(userId: $userId){
      id
      metadata{
        general
      }
    }
  }
`
export const GET_ORGANIZATION_COURSE_USERS = (types: UserCourseResponseType[]) => gql`
  ${getUserCourseFragment(types)}
  query GetOrganizationCourseUsers($organizationId: String!, $companyId: String, $courseId: String!, $searchText: String!,
    $sortBy: OrganizationUsersSortField!, $sortDirection: SortDirection!,
    $skip: Int!, $limit: Int!, $filters: [OrganizationUserFilter!]
  ) {
    usersRes: getOrganizationCourseUsers2(organizationId: $organizationId, companyId: $companyId, courseId: $courseId, searchText: $searchText,
      sortBy: $sortBy, sortDirection: $sortDirection,
      skip: $skip, limit: $limit, filters: $filters
    ) {
      courseId
      organizationId
      usersCount
      users {
        ...${getUserCourseFragmentName(types)}
      }
      influencers {
        userId
        userFullName
        userEmail
        totalNumOfCommentsLikesShares
      }
    }
  }
`

export type ExportCompanyUsersExternalProgressVars = {
  organizationId: string,
  companyId: string,
  searchText: string,
}
export type ExportCompanyUsersExternalProgressRes = {
  res: {
    availableAt: string,
    emailTo: string,
    exportId: string,
  },
}
export const EXPORT_COMPANY_USERS_EXTERNAL_PROGRESS = gql`
  query ExportCompanyUsersExternalProgress(
    $organizationId: String!, $companyId: String!,
    $searchText: String!
  ) {
    res: exportCompanyUsersExternalProgress(
      organizationId: $organizationId,
      companyId: $companyId,
      searchText: $searchText
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`

export type GetOrganizationBillingManagersRes = {
  res: {
    invitedUsers: UserInvited[],
    users: (UserBasic&UserEmail)[],
  },
}
export type GetOrganizationBillingManagersVars = { organizationId: string }
export const GET_ORGANIZATION_BILLING_MANAGERS = gql`
  ${getUserInvitedFragment()}
  ${getUserFragment(['with_email'])}
  query GetOrganizationBillingManagers($organizationId: String!) {
    res: getOrganizationBillingManagers(organizationId: $organizationId) {
      invitedUsers {
        ...${getUserInvitedFragmentName()}
      }
      users {
        ...${getUserFragmentName(['with_email'])}
      }
    }
  }
`

export type GetOrganizationManagersRes = {managers: {users: (UserBasic&UserEmail)[], invitedUsers: UserInvited[]}}
export const GET_ORGANIZATION_MANAGERS = gql`
  ${getUserFragment(['with_email'])}
  ${getUserInvitedFragment()}
  query GetOrganizationManagers($organizationId: String!) {
    managers: getOrganizationManagers(organizationId: $organizationId) {
      users {
        ...${getUserFragmentName(['with_email'])}
      }
      invitedUsers {
        ...${getUserInvitedFragmentName()}
      }
    }
  }
`
export type GetOrganizationEditorsRes = {editors: {users: (UserBasic&UserEmail)[], invitedUsers: UserInvited[]}}
export const GET_ORGANIZATION_EDITORS = gql`
  ${getUserFragment(['with_email'])}
  ${getUserInvitedFragment()}
  query GetOrganizationEditors($organizationId: String!) {
    editors: getOrganizationEditors(organizationId: $organizationId) {
      users {
        ...${getUserFragmentName(['with_email'])}
      }
      invitedUsers {
        ...${getUserInvitedFragmentName()}
      }
    }
  }
`

export type GetUserCustomAttributesRes = { res: {
  key: string,
  value: string,
  type: string,
}[]
}

export type GetUserCustomAttributesVars = { organizationId: string, userId: string }

export const GET_USER_CUSTOM_ATTRIBUTES = gql`
  query GetUserCustomAttributes($organizationId: String!, $userId: String!){
    res: getUserCustomAttributes(
      organizationId: $organizationId, 
      userId: $userId
    ) {
      key
      value
      type
    }
  }
`